<template>
  <b-card class="shadow-sm">
    <form @submit.prevent="handleSubmit">
      <b-form-input v-model="otpCopy"></b-form-input>
      <p v-if="error" class="text-danger">
        {{ error }}
      </p>
      <hr class="py-0" />
      <b-button type="submit" variant="primary" block>Submit</b-button>
    </form>
    <template v-slot:footer>
      <div class="text-center w-100">
        <small>
          Didn't receive a code? <a href="#" @click.prevent="requestOtp">Resend Code </a>
        </small>
      </div>
    </template>
  </b-card>
</template>

<script>
  export default {
    name: 'OtpForm',

    props: {
      otp: {
        type: String,
        required: true
      },
      error: {
        type: String,
        required: false
      }
    },

    data () {
      return {
        otpCopy: this.otp
      }
    },

    methods: {
      handleSubmit () {
        this.$emit('submitting', this.otpCopy)
      },

      requestOtp () {
        this.$emit('requestOtp')
      }
    }
  }
</script>

<style>
</style>
