<template>
  <b-container>
    <b-row
      style="height: 100vh"
      class="justify-content-center py-4 align-items-center"
    >
      <b-col lg="4">
        <div class="w-100 text-center mb-4">
          <p class="mb-0 font-weight-bold text-primary">
            Please check your email for a code we've just send and input it
            here.
          </p>
        </div>
        <otp-form
          :error="error"
          :otp="otp"
          @submitting="checkOtp"
          @requestOtp="resendOtp"
        ></otp-form>
      </b-col>
    </b-row>
    <app-spinner ref="spinner"></app-spinner>
    <app-toast ref="toast"></app-toast>
  </b-container>
</template>

<script>
  import UserService from '@/services/UserService'
  import OtpForm from '@/components/OtpForm'

  export default {
    name: 'AuthOtp',

    components: {
      OtpForm
    },

    data () {
      return {
        otp: '',
        error: ''
      }
    },

    methods: {
      async checkOtp (otpData) {
        this.$refs.spinner.show()

        try {
          const { data } = await UserService.checkOtp({
            userId: this.$store.state.user.id,
            otp: otpData
          })

          // Set user data to vuex and localStorage
          this.$store.commit('SET_USER_DATA', data)
          this.$router.push({ name: 'UserEditor' })
        } catch (error) {
          console.log('error', error.response.data)
          this.error = error.response.data.message
        }

        this.$refs.spinner.hide()
      },

      async resendOtp () {
        this.$refs.spinner.show()

        try {
          await UserService.resendOtp(this.$store.state.user.id)
          this.$refs.spinner.hide()
          this.$refs.toast.success('A new login code is sent successfully.')
        } catch (error) {
          console.log('error', error)
          this.error = error.response.data.message
        }
      }
    }
  }
</script>

<style></style>
